import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./profile.module.styl"
import Img from "gatsby-image"
import SEO from "../components/seo"

export default () => {
  const data = useStaticQuery(graphql`
    query About {
      jsonYaml {
        About {
          img {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          intro_cn
          intro_en
          work_cn
          work_en
        }
        Award {
          cn
          en
        }
        Client
      }
    }
  `)

  const about = data.jsonYaml.About
  const award = data.jsonYaml.Award
  const client = data.jsonYaml.Client
  const currentYear = new Date().getFullYear()

  return (
    <Layout mode="mode_white">
      <SEO
        title="Profile"
        description={`${about.intro_cn};${about.intro_cn};${about.work_cn}`}
        pathname="/profile/"
      />
      <main className={styles.main}>
        <div className={styles.wrap}>
          <div className={styles.banner}>
            <Img
              className={styles.img}
              fluid={about.img.childImageSharp.fluid}
            />
            <span>2009-{currentYear}-moreee</span>
          </div>

          <div className={styles.title}>
            <div className={styles.name_en}>
              <div>
                <span>YidOng</span>
              </div>
              <div>Cai</div>
            </div>
            <div className={styles.name_cn}>蔡依东</div>
          </div>

          <div className={styles.about}>
            <div>
              纽约艺术指导俱乐部会员
              <br />
              日本插画大奖评委
              <br />
              湖南师范大学特聘讲师
            </div>
            <div>
              NewYork ADC（Art Director Club）Member
              <br />
              JIA Japanese Illustration Award Judge
              <br />
              Teacher at Hunan Normal University
            </div>
          </div>

          <div className={styles.intro}>
            <p>
              I specialize in Brand indentites, <br />
              Illustration, Posters, Digital communication advertise,
              <br />
              Brand positioning, Signage, Verpackung,
              <br />
              Books and moreee.
            </p>

            <p>
              专注于品牌视觉识别系统设计、插画、海报设计、
              <br />
              数字化营销、品牌定位、导视系统、书籍设计等...
            </p>
          </div>

          <div className={styles.work}>
            <div>先后任职</div>
            <section>
              <p>
                <span>rifling（创始人）</span>
                <span>Founder/CD</span>
              </p>
              <p>
                <span>环时互动（美术指导）</span>
                <span>Social lab AD</span>
              </p>
              <p>
                <span>奥美上海（美术指导）</span>
                <span>Ogilvy SH AD</span>
              </p>
              <p>
                <span>博尚广告（创意副总监）</span>
                <span>Deep Impressive ACD</span>
              </p>
              <p>
                <span>天与空广告（创意副总监）</span>
                <span>Tian yu kong ACD</span>
              </p>
              <p>
                <span>VMLY&R（创意总监）</span>
                <span>VMLY&R CD</span>
              </p>
              <p>
                <span>华扬联众（创意群总监）</span>
                <span>HY Link GCD</span>
              </p>
            </section>
          </div>

          <div className={styles.award}>
            <label>AWaRd</label>
            <div>
              <section className={styles.award_cn}>
                {award.cn.map((val, idx) => (
                  <div className={styles.award_item} key={idx}>
                    <time>{val[0]}</time>
                    <div>{val[1]}</div>
                  </div>
                ))}
              </section>
              <section className={styles.award_en}>
                {award.en.map((val, idx) => (
                  <div className={styles.award_item} key={idx}>
                    <time>{val[0]}</time>
                    <div>{val[1]}</div>
                  </div>
                ))}
              </section>
            </div>
          </div>

          <div className={styles.last}></div>
        </div>
      </main>
    </Layout>
  )
}
